<template>
    <section id="login-page">
        <div class="container-fluid m-0 p-0">
            <div class="login-top">
                <div class="row" style="margin-right:0px; margin-left:0px;">
                    <div class="col-md-12">
                        <div class="main-logo-title">
                            <img
                                src="../assets/images/recopedia-white.png"
                                alt="logo"
                                width="200"
                            />
                        </div>
                        <h5 class="text-white text-center">
                            Lengkapi Data Anda
                        </h5>
                        <div class="text-center text-white mt-3 mb-4 mx-auto">
                            <h6>
                                Untuk memudahkan verifikasi lengkapi data di<br />
                                bawah ini selengkap mungkin
                            </h6>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="login-card">
                            <div class="card mx-auto p-4 shadow">
                                <!-- Form Login -->
                                <form action="#" id="login-form">
                                    <div class="form-group mb-3">
                                        <label for="name" class="mb-2"
                                            >Nama Lengkap</label
                                        >
                                        <input
                                            type="text"
                                            :readonly="isRead"
                                            v-on:keyup="onKeyUp()"
                                            v-model="name"
                                            class="form-control"
                                            id="name"
                                            placeholder="Masukkan Nama Lengkap"
                                        />
                                    </div>
                                    <div class="form-group mb-3">
                                        <label for="phone" class="mb-2"
                                            >Nomor HP</label
                                        >
                                        <div class="row">
                                            <div class="col-3 pe-1">
                                                <select
                                                    name=""
                                                    id=""
                                                    v-model="format_phone"
                                                    class="form-select"
                                                    :readonly="isRead"
                                                >
                                                    <option value="62">
                                                        +62
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col ps-1">
                                                <input
                                                    type="number"
                                                    v-on:keyup="onKeyUp()"
                                                    :readonly="isRead"
                                                    v-model="phone"
                                                    min="0"
                                                    maxlength="14"
                                                    name="pass-1"
                                                    class="form-control"
                                                    id="phone"
                                                    placeholder="Contoh: 82123456789"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group mb-3">
                                        <label for="name" class="mb-2"
                                            >Rumah Sakit</label
                                        >
                                        <input
                                            type="text"
                                            :readonly="isRead"
                                            v-on:keyup="onKeyUp()"
                                            v-model="rumah_sakit"
                                            class="form-control"
                                            id="rumah_sakit"
                                            required
                                            placeholder="Masukkan Nama Rumah Sakit"
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label for="npa" class="mb-2"
                                            >NPA</label
                                        >
                                        <input
                                            type="text"
                                            v-on:keyup="onKeyUp()"
                                            :readonly="isRead"
                                            v-model="npa"
                                            name="pass-2"
                                            class="form-control"
                                            id="npa"
                                            placeholder="Masukkan NPA"
                                        />
                                    </div>
                                    <div class="d-grid my-3">
                                        <button
                                            v-if="loadRegister"
                                            disabled
                                            class="btn btn-done-register"
                                            type="button"
                                        >
                                            <i
                                                class="fa fa-spinner fa-spin"
                                            ></i>
                                            SELESAIKAN DAFTAR
                                        </button>
                                        <button
                                            v-else
                                            class="btn btn-done-register py-2"
                                            v-on:click="register()"
                                            type="button"
                                            :disabled="submit_on"
                                        >
                                            SELESAIKAN DAFTAR
                                        </button>
                                    </div>
                                </form>
                                <!-- End Form Login -->
                            </div>
                        </div>
                    </div>
                    <div class="col-12 text-center mt-3 pt-5 fw-bold mb-5">
                        <footer>
                              <img src="../assets/images/anhi-logo-OG.png" style="width:150px;"/>
                        </footer>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { getError } from '@/utils/helpers';
import DokterService from '@/services/DokterService';

export default {
    name: 'registerconfirm',
    data() {
        return {
            loadRegister: false,
            isRead: false,
            alert: 'none',
            alertMessage: '',
            name: '',
            npa: '',
            rumah_sakit:'',
            phone: '',
            format_phone: '62',
            submit_on: true,
        };
    },
    methods: {
        async register() {
            this.loadRegister = true;
            this.isRead = true;
            const payload = {
                npa: this.npa,
                name: this.name,
                hospital_name:this.rumah_sakit,
                phone: this.format_phone + '' + this.phone,
            };
            await DokterService.updateUser(payload)
                .then((response) => this.onResponse(response))
                .catch((error) => getError(error));
        },
        async onResponse(response) {
            if (response.data.result == 'error') {
                this.alert = 'block';
                this.alertMessage = response.data.message;
            } else {
                await this.$store.dispatch('auth/getAuthUser');
                this.$router.push('/dashboard');
            }
            this.loadRegister = false;
            this.isRead = false;
        },
        onKeyUp() {
            if (this.npa && this.name && this.phone) {
                this.submit_on = false;
            } else {
                this.submit_on = true;
            }
        },
    },
};
</script>
